import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import Loading from '~/components/Loading';
import { FourBrothersScheduleQuery } from '~/types/schema';

import MoviesComponent from './Component';
import GET_SHOWTIMES from './GetShowtimes.query.gql';
import { encode } from './helpers';

interface Props {
  theaterId: string;
  showdate?: string;
}

const Movies: React.FC<Props> = ({ theaterId, showdate }) => {
  const [data, setData] = useState<
    FourBrothersScheduleQuery | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!showdate) {
      return;
    }
    setLoading(true);
    fetch(
      'https://fourbrothers.filmsxpress.com/.netlify/functions/fetchAthena',
      {
        method: 'post',
        body: JSON.stringify({
          query: GET_SHOWTIMES.loc?.source.body,
          variables: {
            theaterId,
            from: showdate,
            to: DateTime.fromISO(showdate)
              .plus({ day: 1 })
              .toISODate(),
            locale: 'en_US',
            theaterCode: theaterId,
          },
        }),
      },
    )
      .then((res) => res.json())
      .then((res) => setData(res.data))
      .finally(() => setLoading(false));
  }, [theaterId, showdate]);

  const movies = encode(data);
  if (!loading && !movies.length) {
    return (
      <div
        css={(theme) => ({
          fontWeight: theme.font.weight.bold,
          padding: '2em',
          textAlign: 'center',
        })}
      >
        No Showtimes Available Today. Please Choose Another Date
      </div>
    );
  }
  return (
    <Loading isLoading={loading}>
      <MoviesComponent movies={encode(data)} />
    </Loading>
  );
};

export default Movies;
