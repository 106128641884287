import React, { useState } from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';

import MovieComponent from './Movie';
import type { Movie } from './types';

interface Props {
  movies: Movie[];
}

const MoviesComponent: React.FC<Props> = ({ movies }) => {
  const [modalTrailer, setModalTrailer] = useState<
    string | undefined
  >(undefined);

  const handleSetModalTrailer = (trailer: string) => {
    setModalTrailer(trailer);
  };

  return (
    <div
      css={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        margin: '2em 0 0 0',
      }}
    >
      {movies.map((movie) => (
        <MovieComponent
          key={movie.id}
          movie={movie}
          handleSetModalTrailer={handleSetModalTrailer}
        />
      ))}
      <Modal
        isOpen={!!modalTrailer}
        onRequestClose={() => {
          setModalTrailer(undefined);
        }}
        shouldCloseOnOverlayClick
        style={{
          overlay: {
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            justifyContent: 'center',
            zIndex: 10000,
          },
        }}
        css={(theme) => ({
          width: '18.75em',
          [theme.mediaQuery.tablet]: {
            width: '50%',
          },
        })}
      >
        <div
          css={{
            height: 0,
            paddingBottom: '56.25%',
            position: 'relative',
            width: '100%',
          }}
        >
          <ReactPlayer
            url={modalTrailer}
            width="100%"
            height="100%"
            playing={true}
            playsinline
            style={{
              backgroundColor: 'black',
              position: 'absolute',
            }}
            controls
          />
        </div>
      </Modal>
    </div>
  );
};

export default MoviesComponent;
