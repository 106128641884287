import { ResponsiveType } from 'react-multi-carousel';
import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';

import { FourBrothersShowdatesQuery } from '~/types/schema';

import { BUTTON_WIDTH } from './constants';
import { Showdate } from './types';

const encodeShowdate = (
  showdate: DateTime,
  showdates: string[] = [] as string[],
): Showdate => ({
  day: showdate.toFormat('EEE'),
  date: showdate.toFormat('d'),
  month: showdate.toFormat('MMM'),
  isoDate: showdate.toISODate(),
  clickable: showdates.includes(showdate.toISODate()),
});

const baseShowdates = (): Showdate[] => {
  const showdates: Showdate[] = [];
  const today = DateTime.local();
  for (let i = 0; i < 8; i++) {
    const showdate = today.plus({ days: i });
    showdates.push(encodeShowdate(showdate));
  }
  return showdates;
};

export const encode = (
  data?: FourBrothersShowdatesQuery,
): Showdate[] => {
  if (!data?.theater?.schedule?.nodes?.length) {
    return baseShowdates();
  }

  const dataShowdates = uniq(
    (data?.theater?.schedule?.nodes || []).reduce(
      (dates: string[], node) => [
        ...dates,
        ...[...node.scheduledDays],
      ],
      [],
    ),
  ).sort();

  if (!dataShowdates.length) {
    return baseShowdates();
  }

  const start = DateTime.fromISO(dataShowdates[0]);
  const end = DateTime.fromISO(
    dataShowdates[dataShowdates.length - 1],
  );
  const diff = end.diff(start, 'days');
  const showdates: Showdate[] = baseShowdates();
  for (let i = 0; i <= diff.days; i += 1) {
    const nextShowdate = encodeShowdate(
      start.plus({ days: i }),
      dataShowdates,
    );
    const showdateIndex = showdates.findIndex(
      (showdate) => showdate.isoDate === nextShowdate.isoDate,
    );
    if (showdateIndex !== -1) {
      showdates[showdateIndex] = nextShowdate;
    } else {
      showdates.push(nextShowdate);
    }
  }

  return showdates;
};

export const getCarouselResponsiveSettings = (
  containerWidth: number,
): ResponsiveType => {
  const settings: ResponsiveType = {};
  let min = 0;
  const offset = window.innerWidth - containerWidth;
  for (let i = 1; i < 8; i++) {
    const max = i === 7 ? 9999 : BUTTON_WIDTH * (i + 1) + offset;
    settings[i] = {
      breakpoint: { max, min },
      items: i,
      slidesToSlide: i,
    };
    min = max + 1;
  }
  return settings;
};
