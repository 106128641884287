import React from 'react';
import { css, Global, ThemeProvider } from '@emotion/react';

const getMediaQuery = (px: number): string =>
  `@media screen and (min-width: ${px > 0 ? px / 16 : px}em)`;

const ThemeWrapper: React.FC = ({ children }) => {
  const offset =
    window?.innerWidth > 0
      ? window.innerWidth -
        (document.getElementById('fxp-widget')?.offsetWidth || 0)
      : 0;
  return (
    <ThemeProvider
      theme={{
        mediaQuery: {
          mobileXL: getMediaQuery(400 + offset),
          tablet: getMediaQuery(768 + offset),
          tabletXL: getMediaQuery(1024 + offset),
          desktop: getMediaQuery(1200 + offset),
          desktopXL: getMediaQuery(1400 + offset),
          max: getMediaQuery(1600 + offset),
        },
        font: {
          family: {
            text: '"Raleway", sans-serif',
            date: '"Arial Narrow", Arial, sans-serif',
          },
          weight: {
            normal: 400,
            semiBold: 600,
            bold: 700,
          },
        },
        colors: {
          primary: '#0a85a7',
          secondary: '#bb0504',
          white: '#ffffff',
          offWhite: '#f2f2f2',
          gray: '#9d9897',
          wewak: '#F5A194',
          black: '#000000',
          darkGray: '#303030',
          lightGray: '#818181',
          background: '#fbf6e0',
        },
      }}
    >
      <Global
        styles={css`
          @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;600;700&display=swap');
        `}
      />
      {children}
    </ThemeProvider>
  );
};

export default ThemeWrapper;
