import { Theme } from '@emotion/react';

import { Showdate } from '../types';

export const getBackgroundColor = (
  showdate: Showdate,
  theme: Theme,
  selectedShowdate?: string,
): string => {
  if (!showdate.clickable) {
    return theme.colors.offWhite;
  }
  if (showdate.isoDate === selectedShowdate) {
    return theme.colors.wewak;
  }
  return theme.colors.white;
};
