import React from 'react';
import { Interpolation, Theme } from '@emotion/react';

interface Props {
  text: string;
  onClick?: () => void;
  linkTo?: string;
  styleOverrides?: Interpolation<Theme>;
  disabled?: boolean;
}

const Button: React.FC<Props> = ({
  text,
  onClick,
  linkTo,
  styleOverrides = {} as Props['styleOverrides'],
  disabled = false,
}) => {
  const buttonStyles: Interpolation<Theme> = [
    (theme: Theme) => ({
      alignItems: 'center',
      backgroundColor: theme.colors.primary,
      border: '0',
      color: theme.colors.white,
      cursor: disabled ? 'unset' : 'pointer',
      display: 'flex',
      fontFamily: theme.font.family.text,
      fontSize: '1em',
      fontWeight: theme.font.weight.semiBold,
      height: '2.5em',
      justifyContent: 'center',
      letterSpacing: '1px',
      maxWidth: '95%',
      textAlign: 'center',
      textDecoration: 'none',
      textTransform: 'uppercase',
      WebkitFontSmoothing: 'antialiased',
      width: '7.875em',
      '&:hover': {
        backgroundColor: disabled
          ? theme.colors.primary
          : theme.colors.secondary,
        transition: 'background-color 0.3s ease',
      },
    }),
    styleOverrides,
  ];
  if (linkTo && !disabled) {
    return (
      <a
        href={linkTo}
        css={buttonStyles}
        target="_blank"
        rel="noreferrer"
      >
        {text}
      </a>
    );
  }
  if (!disabled && onClick) {
    return (
      <button type="button" onClick={onClick} css={buttonStyles}>
        {text}
      </button>
    );
  }
  return <span css={buttonStyles}>{text}</span>;
};

export default Button;
