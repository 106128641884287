import orderBy from 'lodash/orderBy';
import { DateTime, Duration } from 'luxon';

import { FourBrothersScheduleQuery } from '~/types/schema';

import { Movie } from './types';

export const encode = (data?: FourBrothersScheduleQuery): Movie[] => {
  if (!data?.theater?.schedule?.nodes?.length) {
    return [];
  }

  const movies: Movie[] = data.theater.schedule.nodes.reduce(
    (prev, curr) => {
      let moviesIndex = prev.findIndex(
        (movie) => movie.id === curr.movie?.id,
      );
      const movie = curr.movie;
      if (!movie) {
        return prev;
      }
      if (moviesIndex === -1) {
        prev.push({
          id: movie.id,
          name: movie.locale?.title || '',
          release: movie?.releases?.[0]?.releasedAt
            ? DateTime.fromISO(
                movie.releases[0].releasedAt,
              ).toISODate()
            : 'TBA',
          rating: movie?.releases?.[0]?.rating?.certificate || 'NR',
          runtime: movie?.runtime
            ? `${Duration.fromISO(movie.runtime).toFormat('mm')} min.`
            : undefined,
          synopsis: movie?.locale?.synopsis || undefined,
          poster: movie?.locale?.poster?.url
            ? `${movie.locale.poster.url}/r_400_600`
            : undefined,
          trailer: movie?.locale?.videos?.[0]?.url || undefined,
          showtimes: [],
        });
        moviesIndex = prev.length - 1;
      }

      curr.showtimes?.forEach((showtime) => {
        prev[moviesIndex].showtimes.push({
          time: showtime?.startsAt
            ? DateTime.fromFormat(
                showtime.startsAt,
                'yyyy-MM-dd HH:mm:ss',
              ).toFormat('h:mm a')
            : '',
          perfTime: showtime?.startsAt
            ? DateTime.fromFormat(
                showtime.startsAt,
                'yyyy-MM-dd HH:mm:ss',
              ).toFormat('HH:mm')
            : '',
          link: `${showtime?.defaultTicketingUrl}`.trim(),
        });
      });

      prev[moviesIndex].showtimes = orderBy(
        prev[moviesIndex].showtimes,
        'perfTime',
      );

      return prev;
    },
    [] as Movie[],
  );

  return orderBy(
    movies,
    [(movie) => movie.showtimes?.[0]?.perfTime, 'release', 'title'],
    ['asc', 'desc', 'asc'],
  );
};
