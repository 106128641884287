import React, { useState } from 'react';
import { DateTime } from 'luxon';

import Movies from '~/components/Movies';
import Showdates from '~/components/Showdates';
import Wrapper from '~/components/Wrapper';

import { THEATER_ID } from './constants';

const App: React.FC = () => {
  const [selectedShowdate, setSelectedShowdate] = useState<string>(
    DateTime.local().toISODate(),
  );

  return (
    <Wrapper>
      <div
        css={(theme) => ({
          fontFamily: theme.font.family.text,
          boxSizing: 'border-box',
          fontSize: '16px',
          ' > *': {
            boxSizing: 'inherit',
            fontSize: '1em',
          },
        })}
      >
        <Showdates
          theaterId={THEATER_ID}
          selectedShowdate={selectedShowdate}
          handleSetSelectedShowdate={(showdate: string) => {
            setSelectedShowdate(showdate);
          }}
        />
        <Movies theaterId={THEATER_ID} showdate={selectedShowdate} />
      </div>
    </Wrapper>
  );
};

export default App;
