import React, { useState } from 'react';
import Truncate from 'react-truncate';
import parse from 'html-react-parser';

import { LINE_COUNT } from './constants';

interface Props {
  synopsis?: string;
}

const Synopsis: React.FC<Props> = ({ synopsis }) => {
  const [isSynopsisExpanded, setIsSynopsisExpanded] = useState(false);

  if (!synopsis) {
    return null;
  }

  return (
    <Truncate
      lines={!isSynopsisExpanded && LINE_COUNT}
      ellipsis={
        <span>
          ...{` `}
          <span
            css={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => {
              setIsSynopsisExpanded(true);
            }}
          >
            Read more
          </span>
        </span>
      }
    >
      {parse(synopsis)}
      {isSynopsisExpanded && (
        <div
          css={{
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => {
            setIsSynopsisExpanded(false);
          }}
        >
          Read less
        </div>
      )}
    </Truncate>
  );
};

export default Synopsis;
