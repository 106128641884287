import React from 'react';

import Button from '~/components/Button';

interface Props {
  poster?: string;
  title: string;
  trailer?: string;
  handleSetModalTrailer: (trailer: string) => void;
}

const Poster: React.FC<Props> = ({
  poster,
  title,
  trailer,
  handleSetModalTrailer,
}) => {
  return (
    <div
      css={(theme) => ({
        margin: '0 0 1em 0',
        position: 'relative',
        width: '12.5em',
        [theme.mediaQuery.mobileXL]: {
          margin: '0 1em 0 0',
          width: '9.375em',
        },
        [theme.mediaQuery.tablet]: {
          margin: '0 0 1em 0',
          width: '18.125em',
        },
      })}
    >
      <img
        src={poster}
        alt={title}
        css={{
          display: 'block',
          width: '100%',
        }}
      />
      {trailer && (
        <div
          css={{
            alignItems: 'flex-end',
            backgroundColor: 'rgba(0,0,0,0.5)',
            boxSizing: 'border-box',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            left: 0,
            opacity: 0,
            paddingBottom: '3em',
            position: 'absolute',
            top: 0,
            width: '100%',
            '&:hover': {
              opacity: 1,
              transition: 'opacity 0.3s ease',
            },
          }}
        >
          <Button
            onClick={() => {
              handleSetModalTrailer(trailer);
            }}
            text="Trailer"
          />
        </div>
      )}
    </div>
  );
};

export default Poster;
