import { Duration } from 'luxon';

export const displayTitle = (title: string): string => {
  if (!title) return title;

  const trimmed = title.trim();

  if (trimmed.toLowerCase().slice(-5) === ', the') {
    return `The ${trimmed.slice(0, -5)}`;
  }

  if (trimmed.toLowerCase().slice(-4) === ', an') {
    return `An ${trimmed.slice(0, -4)}`;
  }

  if (trimmed.toLowerCase().slice(-3) === ', a') {
    return `A ${trimmed.slice(0, -3)}`;
  }

  return trimmed;
};

export const displayRuntime = (runtime?: string): string => {
  if (!runtime) {
    return '';
  }
  const split = runtime.split(':');

  if (split.length === 1) {
    return `${split[0]} minutes`;
  }

  const hours = parseInt(split[0], 10);
  const minutes = parseInt(split[1], 10);

  const totalMinutes = Duration.fromObject({
    hours,
    minutes,
  })?.as('minutes');

  return totalMinutes ? `${totalMinutes} minutes` : '';
};
