import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import React from 'react';
import Loader from 'react-loader-spinner';
import { useTheme } from '@emotion/react';

interface Props {
  isLoading: boolean;
}

const Loading: React.FC<Props> = ({ isLoading, children }) => {
  const theme = useTheme();

  if (!isLoading) {
    return <>{children}</>;
  }

  return (
    <div
      css={{
        alignItems: 'center',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        padding: '1em',
        width: '100%',
      }}
    >
      <Loader
        type="Rings"
        color={theme.colors.primary}
        height={100}
        width={100}
      />
    </div>
  );
};

export default Loading;
