import React from 'react';

import { Showdate } from '../types';
import { getBackgroundColor } from './helpers';

interface Props {
  showdate: Showdate;
  handleSetSelectedShowdate: (showdate: string) => void;
  selectedShowdate?: string;
}

const ShowdateButton: React.FC<Props> = ({
  showdate,
  handleSetSelectedShowdate,
  selectedShowdate,
}) => (
  <button
    css={(theme) => ({
      alignItems: 'center',
      backgroundColor: getBackgroundColor(
        showdate,
        theme,
        selectedShowdate,
      ),
      border: `2px solid ${
        selectedShowdate === showdate.isoDate
          ? theme.colors.primary
          : 'transparent'
      }`,
      color: showdate.clickable
        ? theme.colors.black
        : theme.colors.gray,
      cursor: showdate.clickable ? 'pointer' : 'default',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      fontFamily: theme.font.family.date,
      fontStretch: 'condensed',
      justifyContent: 'center',
      height: '9.6875em',
      textAlign: 'center',
      textTransform: 'uppercase',
      width: '100%',
    })}
    disabled={!showdate.clickable}
    type="button"
    onClick={() => {
      if (showdate.clickable) {
        handleSetSelectedShowdate(showdate.isoDate);
      }
    }}
  >
    <span css={{ fontSize: '1.5em' }}>{showdate.day}</span>
    <span
      css={(theme) => ({
        fontSize: '3em',
        fontWeight: theme.font.weight.bold,
      })}
    >
      {showdate.date}
    </span>
    <span css={{ fontSize: '1.5em' }}>{showdate.month}</span>
  </button>
);

export default ShowdateButton;
