import React from 'react';
import hexToRgba from 'hex-to-rgba';

interface Props {
  direction: 'next' | 'prev';
  onClick: () => void;
}

const Arrow: React.FC<Props> = ({ onClick, direction }) => {
  return (
    <button
      onClick={onClick}
      css={(theme) => ({
        alignItems: 'center',
        appearance: 'none',
        backgroundColor: theme.colors.primary,
        border: `1px solid ${theme.colors.primary}`,
        borderRadius: '50%',
        color: theme.colors.white,
        cursor: 'pointer',
        display: 'flex',
        height: '48px',
        justifyContent: 'center',
        marginLeft: direction === 'next' ? '-1.2em' : 'auto',
        marginRight: direction === 'prev' ? '-1.2em' : 'auto',
        outline: 0,
        position: 'relative',
        textAlign: 'center',
        transition:
          'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        width: '48px',
        zIndex: 10,
      })}
    >
      <svg
        viewBox="0 0 24 24"
        css={{
          bottom: 0,
          height: '48px',
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
          width: '48px',
        }}
      >
        <path
          css={(theme) => ({
            fill: theme.colors.white,
            '&:hover': {
              backgroundColor: hexToRgba(theme.colors.white, 0.8),
            },
          })}
          d={
            direction === 'next'
              ? 'M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'
              : 'M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'
          }
        />
      </svg>
    </button>
  );
};

export default Arrow;
