import 'react-multi-carousel/lib/styles.css';

import React, { useEffect, useState } from 'react';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import { ClassNames } from '@emotion/react';
import uniqueId from 'lodash/uniqueId';

import Arrow from './Arrow';
import { getCarouselResponsiveSettings } from './helpers';
import ShowdateButton from './ShowdateButton';
import { Showdate } from './types';

interface Props {
  showdates: Showdate[];
  handleSetSelectedShowdate: (showdate: string) => void;
  selectedShowdate?: string;
}

const ShowdatesComponent: React.FC<Props> = ({
  showdates,
  handleSetSelectedShowdate,
  selectedShowdate,
}) => {
  const [ref, setRef] = useState<Carousel | null>(null);
  const [
    responsiveSettings,
    setResponsiveSettings,
  ] = useState<ResponsiveType>(
    getCarouselResponsiveSettings(
      ref?.containerRef?.current?.offsetWidth || 0,
    ),
  );
  useEffect(() => {
    if (ref?.state.containerWidth) {
      setResponsiveSettings(
        getCarouselResponsiveSettings(ref.state.containerWidth),
      );
    }
  }, [ref]);

  return (
    <div
      css={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Arrow
        direction="prev"
        onClick={() => {
          if (!ref?.previous) return;
          ref.previous(ref.state.slidesToShow);
        }}
      />
      <div
        css={(theme) => ({
          backgroundColor: theme.colors.primary,
          border: `16px solid ${theme.colors.primary}`,
          borderRadius: '20px',
          boxSizing: 'border-box',
          flexGrow: 1,
          maxWidth: '80%',
        })}
      >
        <ClassNames>
          {({ css, cx }) => (
            <Carousel
              ref={(el) => setRef(el)}
              swipeable
              draggable
              responsive={responsiveSettings}
              keyBoardControl
              centerMode
              transitionDuration={500}
              arrows={false}
              itemClass={cx(
                'react-multi-carousel-item-custom',
                css({
                  flexGrow: 1,
                  margin: '0 2px 0 0',
                }),
              )}
            >
              {ref?.state.containerWidth ? (
                showdates.map((showdate) => (
                  <ShowdateButton
                    key={uniqueId('showdate')}
                    showdate={showdate}
                    handleSetSelectedShowdate={
                      handleSetSelectedShowdate
                    }
                    selectedShowdate={selectedShowdate}
                  />
                ))
              ) : (
                <div></div>
              )}
            </Carousel>
          )}
        </ClassNames>
      </div>
      <Arrow
        direction="next"
        onClick={() => {
          if (!ref?.next) return;
          ref.next(ref.state.slidesToShow);
        }}
      />
    </div>
  );
};

export default ShowdatesComponent;
