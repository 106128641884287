import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

import { FourBrothersShowdatesQuery } from '~/types/schema';

import ShowdatesComponent from './Component';
import GET_SHOWDATES from './GetShowdates.query.gql';
import { encode } from './helpers';

interface Props {
  theaterId: string;
  handleSetSelectedShowdate: (showdate: string) => void;
  selectedShowdate?: string;
}

const Showdates: React.FC<Props> = ({
  theaterId,
  handleSetSelectedShowdate,
  selectedShowdate,
}) => {
  const [data, setData] = useState<
    FourBrothersShowdatesQuery | undefined
  >(undefined);

  useEffect(() => {
    fetch(
      'https://fourbrothers.filmsxpress.com/.netlify/functions/fetchAthena',
      {
        method: 'post',
        body: JSON.stringify({
          query: GET_SHOWDATES.loc?.source.body,
          variables: {
            theaterId,
            from: DateTime.now().toISODate(),
            to: DateTime.now().plus({ year: 1 }).toISODate(),
          },
        }),
      },
    )
      .then((res) => res.json())
      .then((res) => setData(res.data));
  }, [theaterId]);

  return (
    <ShowdatesComponent
      showdates={encode(data)}
      handleSetSelectedShowdate={handleSetSelectedShowdate}
      selectedShowdate={selectedShowdate}
    />
  );
};

export default Showdates;
