import React from 'react';
import uniqueId from 'lodash/uniqueId';

import Button from '~/components/Button';

import type { Showtime } from '../../types';

interface Props {
  showtimes: Showtime[];
}

const Showtimes: React.FC<Props> = ({ showtimes }) => (
  <div
    css={(theme) => ({
      alignItems: 'flex-start',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      [theme.mediaQuery.mobileXL]: {
        justifyContent: 'flex-start',
      },
    })}
  >
    {showtimes.map((showtime) => (
      <Button
        key={uniqueId('showtime')}
        text={
          showtime.time.substring(0, 1) === '0'
            ? showtime.time.substring(1)
            : showtime.time
        }
        linkTo={showtime?.link}
        disabled={!showtime?.link}
        styleOverrides={{
          margin: '0 0.5em 0.5em 0',
          textTransform: 'uppercase',
        }}
      />
    ))}
  </div>
);

export default Showtimes;
