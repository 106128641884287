import React from 'react';

import type { Movie } from '../types';
import { displayTitle } from './helpers';
import Poster from './Poster';
import Showtimes from './Showtimes';
import Synopsis from './Synopsis';

interface Props {
  movie: Movie;
  handleSetModalTrailer: (trailer: string) => void;
}

const MovieComponent: React.FC<Props> = ({
  movie,
  handleSetModalTrailer,
}) => (
  <div
    css={(theme) => ({
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      margin: '0 0 3em 0',
      width: '100%',
      [theme.mediaQuery.mobileXL]: {
        alignItems: 'flex-start',
        flexDirection: 'row',
      },
      [theme.mediaQuery.tablet]: {
        flexDirection: 'column',
        margin: '0 1em 3em 1em',
        width: '22em',
      },
    })}
  >
    <Poster
      poster={movie?.poster}
      title={movie.name}
      trailer={movie?.trailer}
      handleSetModalTrailer={handleSetModalTrailer}
    />
    <div
      css={(theme) => ({
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        [theme.mediaQuery.mobileXL]: {
          alignItems: 'flex-start',
          flexGrow: 1,
          textAlign: 'left',
          width: '1px',
        },
        [theme.mediaQuery.tablet]: {
          width: '100%',
        },
      })}
    >
      <strong
        css={(theme) => ({
          color: theme.colors.darkGray,
          fontSize: '1.25em',
        })}
      >
        {displayTitle(movie.name)} ({movie.rating})
      </strong>

      <div
        css={(theme) => ({
          color: theme.colors.lightGray,
          display: 'flex',
          flexDirection: 'column',
          fontSize: '0.875em',
          lineHeight: '1.86em',
          margin: '0 0 1em 0',
        })}
      >
        <Synopsis synopsis={movie?.synopsis} />
        {!!movie.runtime ? (
          <strong>Run Time: {movie.runtime}</strong>
        ) : (
          <strong css={{ opacity: 0 }}>Run Time: N/A</strong>
        )}
      </div>
      <Showtimes showtimes={movie.showtimes} />
    </div>
  </div>
);

export default MovieComponent;
